<template>
	<div style="margin: 0 16px;margin-top: 16px;">
		<div v-if="foldId == 0">
			<a-result status="500" title="个人云盘文件夹为空" sub-title="请创建文件夹后再使用">
				<template #extra>
					<a-button type="primary" @click="createdFold">
						创建文件夹
					</a-button>
				</template>
			</a-result>
		</div>
		<div v-if="foldId != 0">
			<upload :id="foldId" v-if="uploadFlash" :hide.sync="hide"></upload>
			<move :id="id" v-if="moveLoad" :hide="hide"></move>
			<share :id="id" v-if="shareLoad" :hide="hide"></share>
			<rename :name="name" :id="id" v-if="renameLoad" :hide="hide"></rename>
			<screen v-if="sizeLoad" :capacity="capacity" @submit="submit"></screen>
			<sTable v-if="tableFlash" :rowSelection="rowSelection" :columns="columns" :data="data" :pagination="pagination"
				@change="change">
				<template slot="action" slot-scope="action">
					<div style="display: flex;">

						<div @click="addprojectfile(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">分享</div>

						<a-popconfirm title="确认是否删除该文件" ok-text="是" cancel-text="否" @confirm="deletefile(action)">
							<div style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">删除</div>
						</a-popconfirm>
						<div @click="rename(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">重命名</div>
						<div @click="move(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">移动</div>
						<!-- <div @click="downloadfile(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">下载</div> -->
						<div @click="downloadFileFromUrl(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">下载</div>
					</div>
				</template>
			</sTable>
		</div>

	</div>
</template>
<script>
import sTable from '../../../components/sTable.vue'
import {
	returnmycloudfile,
	returnmycloudcapacity,
	deletefile,
	downloadfile,
} from '../../../api/anotherIndex'
import screen from '../../../components/disk/screen.vue'
import upload from '../../../components/upload/upload.vue'
import move from '../../../components/disk/cloudFile/move.vue'
import rename from '../../../components/disk/cloudFile/rename.vue'
import share from '../../../components/disk/cloudFile/share.vue'
//import { saveAs } from 'file-saver';
import {
	getCloudiskFileUrl
} from '@/api/others.js'

export default {
	components: {
		sTable,
		upload,
		screen,
		move,
		rename,
		share
	},
	props: ['foldId'],
	data() {
		const rowSelection = {
			onChange: (selectedRowKeys, selectedRows) => {

				let ids = selectedRows.map((n) => {
					return n.id
				})
				this.ids = ids.join(',')
			},
		}
		return {
			checked: false,
			fileType: undefined,
			fileName: '',
			uploadStartTime: '',
			uploadEndTime: '',
			sizeLoad: true,
			hide: false,
			moveLoad: false,
			renameLoad: false,
			uploadFlash: false,
			shareLoad: false,
			id: undefined,
			tableFlash: true,
			name: undefined,
			ids: undefined,
			columns: [{
				title: '文件名',
				dataIndex: 'fileName',
				key: 'fileName',
				ellipsis: true,

			},
			{
				title: '上传时间',
				dataIndex: 'uploadTime',
				key: 'uploadTime',
			},
			{
				title: '大小',
				dataIndex: 'fileSize',
				key: 'fileSize',
			},

			{
				title: '操作',
				key: 'actione',
				dataIndex: 'fileName',
				scopedSlots: {
					customRender: 'action'
				},
				width: '320px'
			},
			],
			data: [],
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				showTotal: total => `共 ${total} 项`,
				// showTotal: total => `共 ${total} 条数据`,
				showSizeChanger: true,
				pageSizeOptions: ['5', '10', '15', '20'],
			},
			rowSelection,
			capacity: undefined
		}
	},
	created() {
		if (this.foldId == 0) {
			return
		}
		this.returnmycloudfile()
	},
	methods: {
		createdFold() {
			/* console.log(this.$parent.$children[0])
			console.log(this.$parent) */
			this.$parent.$children[0].addfolder('init')
		},
		change(data) {
			this.pagination.pageSize = data.pageSize
			this.pagination.current = data.current

			this.returnmycloudfile()
		},
		async returnmycloudfile() {
			this.$store.commit('updateSpin',true)
			const res = await returnmycloudfile({
				PageNum: this.pagination.current,
				PageSize: this.pagination.pageSize,
				FolderId: typeof this.foldId == 'object' ? this.foldId[0] : this.foldId,
				FileType: this.fileType,
				FileName: this.fileName,
				UploadStartTime: this.uploadStartTime,
				UploadEndTime: this.uploadEndTime
			})
			this.$parent.cloudDiskLoad = false

			//console.log(res)
			if (res.success) {
				this.returnmycloudcapacity()
				this.$parent.cloudDiskLoad = true
				this.pagination.total = res.data.count
				this.data = res.data.data

			} else {
				this.returnmycloudcapacity()
				this.$parent.cloudDiskLoad = true

			}
			this.$store.commit('updateSpin',false)
		},
		async returnmycloudcapacity() {
			const res = await returnmycloudcapacity()
			if (res.success) {
				this.capacity = res.data
			}
		},
		submit(data) {
			/* let datas = '?PageNum=' + this.pagination.current + '&PageSize=' + this.pagination.pageSize +
				'&FolderId=' + this.foldId */
			this.fileType = data.fileType;
			this.fileName = data.fileName;
			this.uploadStartTime = data.uploadStartTime;
			this.uploadEndTime = data.uploadEndTime;
			this.pagination.current = 1;
			this.returnmycloudfile()
		},
		move(data) {
			// console.log(data)
			let id = undefined
			if (data) {
				id = data.record.id
			} else {
				id = this.ids
			}
			if (!id) {
				this.$message.error('请勾选所需要移动的文件')
				return
			}
			this.moveLoad = false
			setTimeout(() => {
				this.moveLoad = true
				this.hide = true
				this.id = id
			}, 100)

		},
		async deletefile(data) {
			let id = undefined
			if (data) {
				id = data.record.id
			} else {
				id = this.ids
			}
			if (!id) {
				this.$message.error('请勾选所需要删除的文件')
				return
			}
			//let datad = '?FileIds=' + id
			const res = await deletefile({
				FileIds: id
			})
			if (res.success) {
				//console.log(this.ids)
				this.ids = ''
				this.$message.success('删除成功')
				this.tableFlash = false
				setTimeout(() => {
					this.returnmycloudfile()
					this.tableFlash = true
				}, 100)

			}

		},
		rename(data) {
			this.renameLoad = false
			setTimeout(() => {
				this.id = data.record.id
				this.name = data.slotProps.substr(0, data.slotProps.lastIndexOf('.'));
				this.renameLoad = true
				this.hide = true
			}, 100)
		},
		addprojectfile(data) {
			let id = undefined
			if (data) {
				id = data.record.id
			} else {
				id = this.ids
			}
			if (!id) {
				this.$message.error('请勾选所需要分享的文件')
				return
			}
			this.shareLoad = false
			setTimeout(() => {
				this.shareLoad = true
				this.hide = true
				this.id = id
			}, 100)
		},
		/* downloadfile(data) {
			let id = data.record.id
			let datas = '?FileId=' + id
			const that = this;
			downloadfile(datas).then(async (res) => {
				if (res.data.type === 'application/json') {
					const error = await that.blobToObj(res.data);
					this.$message.error(error.message)
					return Promise.resolve(false);
				}
				const blob = new Blob([res.data], {
					type: 'application/octet-stream'
				}); //处理文档流
				console.log(data.slotProps)
				const fileName = data.slotProps;
				const elink = document.createElement('a');
				elink.download = fileName;
				elink.style.display = 'none';
				elink.href = URL.createObjectURL(blob);
				document.body.appendChild(elink);
				elink.click();
				URL.revokeObjectURL(elink.href); // 释放URL 对象
				document.body.removeChild(elink);
			})

		}, */
		async blobToObj(data) {
			return new Promise((resolve, reject) => {
				let reader = new FileReader();
				reader.readAsText(data, 'utf-8');
				reader.onload = function () {
					try {
						resolve(JSON.parse(reader.result))
					} catch (error) {
						resolve({
							code: 200,
							message: '获取文件信息成功'
						})
					}
				}
			})
		},
		// 附件下载
		async downloadFileFromUrl(file) {
			this.$store.commit('updateSpin', true)
			const fileName = file.slotProps;
			// 获取附件下载链接
			const res = await getCloudiskFileUrl({
				FileId: file.record.id,
				isPreview: false,
				isPublicRead: false,
				saveAsName: fileName,
			});
			if (res.success) {
				const elink = document.createElement('a');
				elink.download = fileName;
				elink.style.display = 'none';
				elink.href = res.data
				//elink.target = "_blank";
				document.body.appendChild(elink);
				elink.click();
				document.body.removeChild(elink);
			}
			this.$store.commit('updateSpin', false)
		}
	},
}
</script>

<style></style>

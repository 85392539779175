<template>
	<div>
		<lightBox @submit="submit" :hide="hide" title="文件分享" class="lightBox">
			<template scope="data">
				<div style="display: flex;align-items: center;">
					<div style="color: black;font-size: 16px;margin-right: 25px;"><span style="color: red;">*
						</span>项目组名称</div>
					<a-select v-model="foldName"  placeholder="请选择项目组" style="width: 200px"  showSearch :filter-option="filterOption">
						<a-select-option :value="item.projectId" v-for="(item,index) in treeData">
							{{item.projectName}}
						</a-select-option>
					</a-select>
				</div>
			</template>
		</lightBox>
	</div>
</template>

<script>
	import lightBox from '../../lightBox.vue'
	import {
		returnmyproject,
		addprojectfile
	} from '../../../api/anotherIndex'
	export default {
		props: ['hide', 'id'],
		data() {
			return {
				foldName: undefined,
				layout: {
					labelCol: {
						span: 4
					},
					wrapperCol: {
						span: 13
					},
				},
				rules: {
					foldName: [{
						required: true,
						message: '文件夹名称不能为空',
						trigger: 'blur'
					}],

				},
				treeData: []
			}
		},
		components: {
			lightBox
		},
		created() {
			//console.log(this.id)
			this.returnmyproject()
		},
		methods: {
			submit() {
				if (!this.foldName) {

					this.$message.error('请选择项目组')
					return
				}
				//let data = '?FileIds=' + this.id + '&ProjectId=' + this.foldName
				this.addprojectfile()
			},
			async addprojectfile() {
				//let data = '?FileIds=' + this.id + '&ProjectId=' + this.foldName
				const res = await addprojectfile({
					FileIds: this.id,
					ProjectId: this.foldName
				})
				
				this.$parent.shareLoad = false
				if (res.success) {
					this.$message.success('文件分享成功')

					this.$parent.hide = false

				}
			},
			async returnmyproject() {
				const res = await returnmyproject()
				if(res.success) {
					this.treeData = res.data
				}
			},
			change(id, kk) {
				//console.log(this.foldName)
			},
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
				);
			},
		}
	}
</script>
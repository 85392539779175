<template>
	<!-- <div v-if="hide">
		<div
			style="z-index: 100; background-color: rgba(0, 0, 0, 0.65);position: fixed;top: 0;width: 100%;height: 100%;left: 0;right: 0;">
		</div>
		<div class="upload">
			<div class="box" style="">
				<a-upload-dragger :headers={Authorization:token} :data="{FolderId:this.id}"  name="file" :multiple="true" :action="getUploadFileUrl"
					@change="handleChange">
					<div style="width: 500px;height: 200px;">
						<p class="ant-upload-drag-icon">
							<a-icon type="inbox" />
						</p>
					
						<p class="ant-upload-hint">
							把文件或文件夹拖入指定区域，完成上传，同样支持点击上传。
						</p>
					</div>

				</a-upload-dragger>
			</div>
			<div style="margin: 12px 0;">
				<a-button @click="close">关闭</a-button>
			</div>
		</div>
	</div> -->
	<a-modal :visible="hide" @cancel="close" :maskClosable="true" :closable="false" :footer="null" :title="null">
		<div>
			<div>
				<a-upload-dragger :headers="{ 'Authorization': token }" :data="{ FolderId: id }" name="file" :multiple="true" :action="getUploadFileUrl" @change="handleChange">
					<div style="width: 500px;">
						<p class="ant-upload-drag-icon">
							<a-icon type="inbox" />
						</p>

						<p class="ant-upload-hint" style="margin: 30px 0;">
							把文件或文件夹拖入指定区域，完成上传，同样支持点击上传。
						</p>
					</div>

				</a-upload-dragger>
			</div>
			<div style="margin: 12px 0; text-align: center;">
				<a-button @click="close">关闭</a-button>
			</div>
		</div>
	</a-modal>
</template>
<script>
export default {
	props: ['hide', 'id'],
	data() {
		return {
			token: 'Bearer ' + window.localStorage.getItem('Authorization')
		};
	},
	methods: {
		handleChange(info) {

			const uid = info.file.uid;

			if (info.file.status == 'removed') {
				return
			}
			if (info.file.response && info.file.response.success) {
				//let data = '?PageNum=1&PageSize=10&FolderId=' + this.$parent.foldId
				this.$parent.returnmycloudfile()
				this.$message.success(`${info.file.name} 上传成功`);
			} else if (info.file.response) {
				info.fileList.forEach(element => {
					if (element.uid == uid) {
						element.status = 'error'
					}
				});
				this.$message.error(`${info.file.name} 上传失败, 错误信息: ${info.file.response.message}`);
			}
		},
		close() {
			this.$parent.hide = false
			this.$parent.uploadFlash = false
		},
		getUploadFileUrl() {
			return `${process.env.VUE_APP_BASE_API}/mycloud/uploadfile`
		}
	},
};
</script>
<style scoped>
.upload {
	width: 500px;
	min-height: 5px;
	background-color: white;
	z-index: 500;
	position: fixed;
	border-radius: 4px;
	left: 50%;
	margin-left: -250px;
	top: 10%;
}

.box {
	padding: 5px;
	width: 500px;
	height: 170px;
	/* z-index: 500; */
}
</style>
<style>
.upload .ant-upload-list-text {
	/* min-height: 5px !important; */
	padding: 5px 0px;
	/* min-height: 40px; */
	max-height: 400px !important;
	overflow-y: auto;
	float: left;
	width: 100%;
	margin-bottom: 12px;
}
</style>

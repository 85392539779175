<template>
	<div>
		<lightBox @submit="submit" :hide="hide" title="移动文件" class="lightBox">
			<template scope="data">
				<div style="display: flex;align-items: center;">
					<div style="color: black;font-size: 16px;margin-right: 25px;"><span style="color: red;">* </span>文件夹名称
					</div>
					<a-tree-select :treeIcon="true" @change="change" v-model="foldName" style="width: 200px"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择文件夹">
						<template slot="icon">
							<a-icon type="folder" />
						</template>
						<a-tree-select-node v-for="(item, index) in treeData" :key="item.levelId" :value="item.levelId"
							:title="item.levelName">
							<template slot="icon">
								<a-icon type="folder" />
							</template>
							<a-tree-select-node v-for="(ite, index) in item.lst" :key="ite.levelId" :value="ite.levelId"
								:title="ite.levelName">
								<template slot="icon">
									<a-icon type="folder" />
								</template>
								<a-tree-select-node v-for="(it, index) in ite.lst" :key="it.levelId" :value="it.levelId"
									:title="it.levelName">
									<template slot="icon">
										<a-icon type="folder" />
									</template>
								</a-tree-select-node>
							</a-tree-select-node>
						</a-tree-select-node>
					</a-tree-select>
				</div>

			</template>
		</lightBox>
	</div>
</template>

<script>
import lightBox from '../../lightBox.vue'
import {
	returnfolderpath,
	movefilepath
} from '../../../api/anotherIndex'
export default {
	props: ['hide', 'id'],
	data() {
		return {
			foldName: undefined,
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 13
				},
			},
			rules: {
				foldName: [{
					required: true,
					message: '文件夹名称不能为空',
					trigger: 'blur'
				}],

			},
			treeData: []
		}
	},
	components: {
		lightBox
	},
	created() {
		console.log(this.id)
		this.returnfolderpath()
	},
	methods: {
		submit() {
			if (!this.foldName) {

				this.$message.error('请选择文件夹')
				return
			}
			const data = {
				FileIds: this.id,
				FolderId: this.foldName
			}
			this.movefilepath(data)
		},
		async movefilepath(data) {
			const res = await movefilepath(data)
			this.$parent.$parent.cloudDiskLoad = false
			this.$parent.moveLoad = false
			if (res.success) {
				//let datas = '?PageSize=1&PageNum=10&FolderId=' + this.$parent.foldId
				this.$parent.returnmycloudfile()

				this.$message.success('文件移动成功')

				this.$parent.hide = false

			} else {
				this.$message.error(res.data.message)
			}
		},
		async returnfolderpath() {
			const res = await returnfolderpath()
			if (res.success) {
				let dutyTypeJson = JSON.stringify(res.data)
				let dutyTypes = JSON.parse(dutyTypeJson.replace(/seconId/g, "levelId")
					.replace(/seconName/g, "levelName").replace(/threeLevelId/g, "levelId").replace(
						/threeLevelName/g, "levelName")
					.replace(/levelFolderlst/g, "lst")
					.replace(/seconFolderlst/g, "lst")
				)
				this.treeData = dutyTypes;
				const that = this;
				this.treeData = this.treeData.map(item => {
					return that.goDeep(item) /*  {
						...item,
						//slots: { icon: 'folder' } ,
						scopedSlots: {
							switcherIcon: 'switch',
							title: 'title',
							icon: 'folder'
						}
						//isLeaf: false,
					} */
				})
			}

		},
		goDeep(item) {
			const that = this;
			item && item.lst && (item.lst = item.lst.map(dir => {
				return that.goDeep(dir)
				/* return {
					...dir
				} */
			}))
			return {
				...item,
				scopedSlots: {
					switcherIcon: 'switch',
					title: 'title',
					icon: 'folder'
				},
				class: 'folder-tree-node'
			};
		},
		change(id, kk) {
			console.log(this.foldName)
		}
	}
}
</script>
